export function initInspectMode() {
  let iframeDoc = null;
  let replayerWrapper = null;
  let iframe = null;
  let canvas = null;

  const toggleButton = document.querySelector('[data-set-mode="inspect"]');
  console.log('Init inspect mode')
  toggleButton.addEventListener('click', toggleScript);

  function toggleScript() {
    if (
      toggleButton.dataset.isScriptEnabled === 'y'
    ) {
      disableInspectMode();
      toggleButton.classList.remove('active', 'bg-[#4950f6]', 'text-white');
      toggleButton.classList.add('bg-[#e5e7eb]', 'text-black');
      toggleButton.querySelector('svg path').setAttribute('fill', '#000');
    } else {
      enableInspectMode();
      toggleButton.classList.remove('bg-[#e5e7eb]', 'text-black');
      toggleButton.classList.add('active', 'bg-[#4950f6]', 'text-white');
      toggleButton.querySelector('svg path').setAttribute('fill', '#fff');
    }
  }

  function locateReplayItems() {
    iframeDoc = null;
    replayerWrapper = null;
    iframe = null;
    canvas = null;
    // --- re-locate them
    replayerWrapper = document.querySelector('.replayer-wrapper');
    if (replayerWrapper === null) return;
    iframe = replayerWrapper.querySelector('iframe');
    canvas = replayerWrapper.querySelector('canvas');
    if (iframe) {
      iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    }
  }

  function iframeBodyEventListener(e) {
    if (e.target.matches('a, button, input, textarea, select, [role="button"], [onclick]')) {
      e.preventDefault();
      e.stopPropagation();
      updateXPathInput(e.target);
    }
  }

  function enableInspectMode() {
    if (toggleButton.dataset.isScriptEnabled === 'y') return;
    toggleButton.dataset.isScriptEnabled = 'y';
    locateReplayItems();
    if (iframe) {
      iframe.style.pointerEvents = 'unset';
    }
    if (canvas) {
      canvas.style.display = 'none';
    }

    if (iframeDoc) {
      iframeDoc.body.addEventListener('click', iframeBodyEventListener, true);
      iframeDoc.body.addEventListener('mouseover', applyMask);
      iframeDoc.body.addEventListener('mouseout', clearMasks);
    }
  }

  function disableInspectMode() {
    toggleButton.dataset.isScriptEnabled = 'n';
    locateReplayItems();
    if (iframe) {
      iframe.style.pointerEvents = 'none';
    }
    if (canvas) {
      canvas.style.display = 'block';
    }
    if (iframeDoc) {
      iframeDoc.body.removeEventListener('click', iframeBodyEventListener, true);
      iframeDoc.body.removeEventListener('mouseover', applyMask);
      iframeDoc.body.removeEventListener('mouseout', clearMasks);
      clearMasks(iframeDoc);
    }
  }

  function applyMask(target) {
    if (
      toggleButton.dataset.isScriptEnabled !== 'y'
    ) return;
    if (iframeDoc === null) {
      return;
    }

    if (iframeDoc.getElementsByClassName('highlight-wrap').length > 0) {
      resizeMask(target);
    } else {
      createMask(target);
    }
  }

  function resizeMask(target) {
    if (iframeDoc === null) {
      return;
    }
    const rect = target.getBoundingClientRect();
    const hObj = iframeDoc.getElementsByClassName('highlight-wrap')[0];
    Object.assign(hObj.style, {
      top: `${rect.top}px`,
      width: `${rect.width}px`,
      height: `${rect.height}px`,
      left: `${rect.left}px`,
    });
  }

  function createMask(target) {
    if (iframeDoc === null) {
      return;
    }
    const rect = target.getBoundingClientRect();
    const hObj = iframeDoc.createElement("div");
    hObj.className = 'highlight-wrap absolute bg-[#812028] opacity-50 cursor-default pointer-events-none transition-all duration-200';
    Object.assign(hObj.style, {
      top: `${rect.top}px`,
      width: `${rect.width}px`,
      height: `${rect.height}px`,
      left: `${rect.left}px`,
    });
    iframeDoc.body.appendChild(hObj);
  }

  function clearMasks() {
    if (iframeDoc === null) {
      return;
    }
    const hwrappers = iframeDoc.getElementsByClassName("highlight-wrap");
    while (hwrappers.length > 0) {
      hwrappers[0].remove();
    }
  }

  function updateXPathInput(element) {
    const xpathInput = document.querySelector('.xpath');
    if (xpathInput) {
      const xpath = getXPath(element);
      xpathInput.value = xpath;
    }
  }

  function getXPath(element) {
    if (element.id !== '') {
      return `//*[@id="${element.id}"]`;
    }
    if (element === document.body) {
      return '/html/body';
    }
    const parts = [];
    while (element && element.nodeType === Node.ELEMENT_NODE) {
      let nbOfPreviousSiblings = 0;
      let hasNextSiblings = false;
      let sibling = element.previousSibling;
      while (sibling) {
        if (sibling.nodeType === Node.ELEMENT_NODE && sibling.nodeName === element.nodeName) {
          nbOfPreviousSiblings++;
        }
        sibling = sibling.previousSibling;
      }
      sibling = element.nextSibling;
      while (sibling) {
        if (sibling.nodeName === element.nodeName) {
          hasNextSiblings = true;
          break;
        }
        sibling = sibling.nextSibling;
      }
      const prefix = element.prefix ? `${element.prefix}:` : '';
      const nth = nbOfPreviousSiblings || hasNextSiblings ? `[${nbOfPreviousSiblings + 1}]` : '';
      parts.push(`${prefix}${element.localName}${nth}`);
      element = element.parentNode;
    }
    return parts.length ? `/${parts.reverse().join('/')}` : '';
  }
}
